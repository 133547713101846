import React, { Fragment } from 'react';
import { Link } from "gatsby"
import { css } from "@emotion/core";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet";
import * as ROUTES from '../constants/routes';
import SignInForm, {
  SignInGoogle,
  SignInFacebook,
  SignInSaml,
  SignInTwitter,
} from '../components/SignIn';
import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget'; 

const SignInPage = () => (
  <Fragment>
    <h1>Alternate Login</h1>
    <SignInForm />
    
    <PasswordForgetLink />
    <Link to={ROUTES.LANDING} className="signUpBACK"> Back</Link>
  </Fragment>
);

export default () => (
  <Layout>
    <SEO title="Compass Minerals SaltSmart" />
      <Helmet defer={true}>
        <body className="login altLogin" />
      </Helmet>
      <header className="mainHead flexContainer homeHead  faCenter fjCenter">      
        <div className="fCol12 menuCtrl">
          <div className="headerTitle">Home</div>
        
        </div>
      </header>

      <section id="home-content" className="flexContainer secondaryTab">
      <div className="fCol12 maxTablet">
        <div className="flexContainer homeInner">
          <SignInPage />
        </div>
        <div className="compassLogo"/>
      </div>
    </section>    
  </Layout>  
);